<template>
  <g :fill="color" fill-rule="evenodd">
    <circle cx="9" cy="9" r="9" />
    <path
      fill="#FFF"
      fill-rule="nonzero"
      d="M5.907 7.093a.7.7 0 0 0-.99.99l3.176 3.177a.7.7 0 0 0 .99 0l3.177-3.177a.7.7 0 1 0-.99-.99L8.588 9.775 5.907 7.093z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
